import { iconPath } from "../icons";

export const aspectRatio: Record<string, string> = {
  "1:1": "aspect-1/1",
  "7:3": "aspect-7/3",
  "3:1": "aspect-3/1",
  "4:3": "aspect-4/3",
  "2:1": "aspect-2/1",
};

export const enumAspectRatio = Object.keys(
  aspectRatio
) as (keyof typeof aspectRatio)[];

export const ratioWidth = {
  "1:1": 2000,
  "7:3": 2000,
  "3:1": 2000,
  "4:3": 2000,
  "2:1": 2000,
};

export const ratioHeight = {
  "1:1": 2000,
  "7:3": 857,
  "3:1": 667,
  "4:3": 1500,
  "2:1": 1000,
};

export const ratio: Record<string, number> = {
  "1:1": 1,
  "7:3": 2.33,
  "3:1": 3,
  "4:3": 1.33,
  "2:1": 2,
};

export const bgPosition = {
  Center: "bg-center",
  Top: "bg-top",
  Bottom: "bg-bottom",
  Left: "bg-left",
  Right: "bg-right",
  "Top-Right": "bg-right-top",
  "Top-Left": "bg-left-top",
  "Bottom-Right": "bg-right-bottom",
  "Bottom-Left": "bg-left-bottom",
};

export const objectPosition: Record<string, string> = {
  Center: "object-center",
  Top: "object-top",
  Bottom: "object-bottom",
  Left: "object-left",
  Right: "object-right",
  "Top-Right": "object-right-top",
  "Top-Left": "object-left-top",
  "Bottom-Right": "object-right-bottom",
  "Bottom-Left": "object-left-bottom",
};

export const enumBgPosition = Object.keys(
  bgPosition
) as (keyof typeof bgPosition)[];

export const bgFilter = {
  None: "",
  "Darken-1": "brightness-75",
  "Darken-2": "backdrop-brightness-50",
  "Darken-3": "backdrop-brightness-30",
  "Lighten-1": "backdrop-brightness-110",
  "Lighten-2": "backdrop-brightness-125",
  "Lighten-3": "backdrop-brightness-150",
};

export const imgFilter: Record<string, string> = {
  None: "",
  "Darken-1": "brightness-75",
  "Darken-2": "brightness-50",
  "Darken-3": "brightness-[.3]",
  "Lighten-1": "brightness-110",
  "Lighten-2": "brightness-125",
  "Lighten-3": "brightness-150",
};

export const enumFilter = Object.keys(bgFilter) as (keyof typeof bgFilter)[];

export const textSize = {
  "Extra-Large": "text-3xl sm:text-5xl",
  Large: "text-3xl sm:text-4xl",
  Medium: "text-2xl sm:text-3xl",
  Small: "text-xl sm:text-2xl",
  "Extra-Small": "text-lg sm:text-xl",
};

export const enumTextSize = Object.keys(textSize) as (keyof typeof textSize)[];

export const contentWidth: Record<string, string> = {
  "xs-320px": "max-w-xs",
  "sm-348px": "max-w-sm",
  "md-448px": "max-w-md",
  "lg-512px": "max-w-lg",
  "xl-576px": "max-w-xl",
  "2xl-672px": "max-w-2xl",
  "3xl-768px": "max-w-3xl",
  "4xl-896px": "max-w-4xl",
  "5xl-1024px": "max-w-5xl",
  "6xl-1152px": "max-w-6xl",
  "7xl-1280px": "max-w-7xl",
  Full: "",
};

export const enumContentWidth = Object.keys(
  contentWidth
) as (keyof typeof contentWidth)[];

export const enumIcons = Object.keys(iconPath) as (keyof typeof iconPath)[];

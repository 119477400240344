import {
  Component,
  Show,
  mergeProps,
  For,
  createSignal,
  useContext,
  createMemo,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { BannerInnerContentFields } from "./BannerInnerContent.block";
import { builderImage } from "~/utils/builder";
import { Icon } from "solid-heroicons";
import {
  playPause,
  speakerWave,
  speakerXMark,
  xMark,
} from "solid-heroicons/outline";
import {
  imgFilter,
  objectPosition,
  contentWidth,
  aspectRatio,
  ratio,
} from "~/utils/builder/enums";
import Button from "./Button";
import { SiteContext } from "~/utils/contexts";

import { useSiteContext } from "~/utils/contexts";

const PLACEHOLDER_IMAGE =
  "https://cdn.builder.io/api/v1/image/assets%2Fab24932172a5427f981ba9d6a9b615a6%2Fb2b64056b7eb4dec9da939f8f81019e3";

const CONTENT_POSITION: Record<string, string> = {
  Center: "justify-center items-center",
  Top: "justify-center items-start",
  Bottom: "justify-center items-end",
  Left: "justify-start items-center",
  Right: "justify-end items-center",
  "Top-Right": "justify-end items-start",
  "Top-Left": "justify-start items-start",
  "Bottom-Right": "justify-end items-end",
  "Bottom-Left": "justify-start items-end",
};
const TEXT_ALIGN: Record<string, string> = {
  Center: "items-center text-center",
  Top: "items-center text-center",
  Bottom: "items-center text-center",
  Left: "items-start text-left",
  Right: "items-end text-right",
  "Top-Right": "items-end text-right",
  "Top-Left": "items-start text-left",
  "Bottom-Right": "items-end text-right",
  "Bottom-Left": "items-start text-left",
};
const TEXT_COLOUR: Record<string, string> = {
  White: "text-white",
  Black: "text-black",
};
const TEXT_SIZE: Record<string, string> = {
  "Extra-Large": "text-3xl sm:text-5xl",
  Large: "text-3xl sm:text-4xl",
  Medium: "text-2xl sm:text-3xl",
  Small: "text-xl sm:text-2xl",
  "Extra-Small": "text-lg sm:text-xl",
};

const BannerInnerContent: Component<BannerInnerContentFields> = (inProps) => {
  const props = mergeProps(
    {
      bannerImage: PLACEHOLDER_IMAGE,
      bannerAlt: "Banner image",
      aspectRatioLg: "7:3",
      aspectRatioSm: "4:3",
      bgFilter: "None",
      bgPosition: "Center",
      textColour: "White",
      header: "Header Text",
      headerTextSize: "Extra-Large",
      subheader: "Subheader Text",
      subheaderTextSize: "Small",
      fullWidth: true,
    },
    inProps
  );
  const { breakpoints } = useSiteContext();
  // const { breakpoints } = useContext(SiteContext);
  const [showBanner, setShowBanner] = createSignal(true);
  const [isMuted, setIsMuted] = createSignal(true);
  let vidRef: HTMLVideoElement;
  const handlePlayPause = () => {
    if (vidRef) {
      vidRef.paused == true ? vidRef.play() : vidRef.pause();
    }
  };
  const toggleMute = () => {
    if (vidRef) {
      setIsMuted(!isMuted());
    }
  };

  const sizes = [400, 640, 800, 1000, 1200, 1500, 1800];

  const qualityBySize: Record<number, number> = {
    400: 93, //95
    640: 94, //95
    800: 95, //95
    1000: 90, //90
    1200: 85, //90
    1500: 80, //85
    1800: 80, //80
  };

  const aspectRatioNum = createMemo(() =>
    !breakpoints.sm ? ratio[props.aspectRatioSm] : ratio[props.aspectRatioLg]
  );

  const sourceset = createMemo(() => {
    return sizes
      .map((size) => {
        const s = Math.trunc(size * 0.98);

        return `${builderImage({
          src: props.bannerImage,
          format: "webp",
          quality: qualityBySize[size],
        })}&width=${
          props.layout === "2-Column" && size > 640
            ? `${Math.trunc(s / 2)}`
            : `${s}`
        }&height=${
          props.layout === "2-Column" && size > 640
            ? `${Math.trunc(s / aspectRatioNum() / 2)}`
            : `${Math.trunc(s / aspectRatioNum())}`
        } ${size}w`;
      })
      .join(", ");
  });

  return (
    <div
      class="w-full relative flex overflow-hidden object-cover"
      classList={{
        "boundary-outer": !props.fullWidth,
        [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
        [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
        [CONTENT_POSITION[props.contentPosition!]]: !!props.contentPosition,
        [TEXT_COLOUR[props.textColour]]: !!props.textColour,
      }}
    >
      <Show when={showBanner()}>
        {/* <Img
          // src={props.bannerImage}
          src={`${builderImage({
            src: props.bannerImage,
            format: "webp",
            quality: quality(),
          })}`}
          layout="fullWidth"
          aspectRatio={
            !breakpoints.sm
              ? ratio[props.aspectRatioSm]
              : ratio[props.aspectRatioLg]
          }
          class="absolute inset-0 -z-10 pointer-events-none"
          alt={props.bannerAlt ?? ""}
          classList={{
            [imgFilter[props.bgFilter]]: !!props.bgFilter,
            [objectPosition[props.bgPosition]]: !!props.bgPosition,
          }}
          breakpoints={sizes}
          sizes={`(min-width: 640px) 96vw, 48vw`}
        /> */}

        <img
          // class="absolute inset-0 -z-10 pointer-events-none"
          class="absolute inset-0  pointer-events-none"
          alt={props.bannerAlt ?? ""}
          loading={props.loading ?? "lazy"}
          decoding={props.loading === "eager" ? "sync" : "async"}
          // style={`object-fit: cover; width: 100%; aspect-ratio: ${aspectRatioNum()} / 1`}
          style={{
            "object-fit": "cover",
            "width": "100%",
            "aspect-ratio": `${aspectRatioNum()/1}`
          }}
          srcset={sourceset()}
          src={builderImage({
            src: props.bannerImage,
            format: "webp",
            quality: 80,
          })}
          fetchpriority={props.fetchPriority ?? "low"}
          classList={{
            [imgFilter[props.bgFilter]]: !!props.bgFilter,
            [objectPosition[props.bgPosition]]: !!props.bgPosition,
          }}
        />
        <Show
          when={
            props.bannerType === "Video" &&
            (props.bannerVideo || props.bannerVideoSrc)
          }
        >
          <div
            class="absolute w-full h-full"
            classList={{
              [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
              [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
            }}
          >
            <video
              ref={(ref) => (vidRef = ref)}
              src={props.bannerVideo ?? props.bannerVideoSrc}
              preload={breakpoints.lg ? "auto" : "none"}
              autoplay={breakpoints.lg}
              muted={isMuted()}
              loop
              playsinline
              class="object-cover w-full h-full"
              classList={{
                [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
                [`sm:${aspectRatio[props.aspectRatioLg]}`]:
                  !!props.aspectRatioLg,
                [objectPosition[props.videoPos!]]: !!props.videoPos,
              }}
            />
          </div>
        </Show>
        <div
          class="flex flex-col gap-2 md:gap-5 p-4 md:p-10 z-10"
          classList={{
            [TEXT_ALIGN[props.contentPosition!]]: !!props.contentPosition,
          }}
        >
          <div
            class={`px-2 flex ${
              props.swapHeaderPos ? "flex-col-reverse" : "flex-col"
            }`}
            classList={{
              [contentWidth[props.contentWidth!]]: !!props.contentWidth,
            }}
          >
            <Dynamic
              component={props.headingLevel ?? "h2"}
              class="font-bold"
              classList={{
                [TEXT_SIZE[props.headerTextSize!]]: !!props.headerTextSize,
              }}
            >
              {props.header}
            </Dynamic>
            <div
              class="font-medium"
              classList={{
                [TEXT_SIZE[props.subheaderTextSize]]: !!props.subheaderTextSize,
              }}
            >
              {props.subheader}
            </div>
          </div>
          <Show when={props.buttons || props.linkToExternalVideo}>
            <div class="flex gap-2 items-center">
              <For each={props.buttons}>
                {(button) => (
                  <Button
                    aria-label={button.altLabel ?? button.label}
                    style={
                      props.buttonColour === "Black" ? "solid" : "solidWhite"
                    }
                    href={button.href}
                    generic={button.openNewTab}
                    target={button.openNewTab ? "_blank" : undefined}
                  >
                    <Show when={button.altLabel}>
                      <span class="sr-only">{button.altLabel}</span>
                    </Show>
                    <span class="px-4">{button.label}</span>
                  </Button>
                )}
              </For>
              <Show when={props.linkToExternalVideo}>
                <Button
                  style={
                    props.buttonColour === "Black" ? "solid" : "solidWhite"
                  }
                  onClick={() => {
                    setShowBanner(false);
                  }}
                >
                  <span class="px-4">{props.playButtonText}</span>
                </Button>
              </Show>
            </div>
          </Show>
          <Show
            when={
              props.bannerType === "Video" &&
              (props.bannerVideo || props.bannerVideoSrc)
            }
          >
            <div class="absolute  bottom-0 right-0 p-2 flex gap-2">
              <button
                aria-label="Play/pause video"
                class="  hover:text-roma-grey transition"
                onClick={handlePlayPause}
              >
                <Icon path={playPause} class="w-6" />
              </button>
              <Show when={props.videoHasAudio}>
                <button
                  aria-label={isMuted() ? "Un-mute audio" : "Mute audio"}
                  class=" hover:text-roma-grey transition"
                  onClick={toggleMute}
                >
                  <Icon
                    path={isMuted() ? speakerWave : speakerXMark}
                    class="w-5"
                  />
                </button>
              </Show>
            </div>
          </Show>
        </div>
      </Show>
      <Show when={!showBanner()}>
        <div
          class="w-full pt-8"
          classList={{
            [aspectRatio[props.aspectRatioSm]]: !!props.aspectRatioSm,
            [`sm:${aspectRatio[props.aspectRatioLg]}`]: !!props.aspectRatioLg,
          }}
        >
          <div class="flex justify-end items-center text-black pb-2 ">
            <button
              aria-label="Close video"
              class="inline-flex items-center text-xs sm:text-base font-medium"
              onClick={() => setShowBanner(true)}
            >
              <Icon path={xMark} class="w-4 mr-2" />
              <span class="underline-hover underline-black">Close Video</span>
            </button>
          </div>
          <iframe
            class="w-full aspect-video"
            src={`https://www.youtube.com/embed/${props.youtubeId}?autoplay=1&rel=0`}
            // @ts-ignore
            wmode="transparent"
            type="video/mp4"
            width="100%"
            height="100%"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowfullscreen
            title={props.header}
          >
            Video playback not supported in this browser.
          </iframe>
        </div>
      </Show>
    </div>
  );
};

export default BannerInnerContent;

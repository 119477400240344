import { Component, Show, mergeProps } from "solid-js";
import { A } from "@solidjs/router";
import { HeaderWithContentFields } from "./HeaderWithContent.block";

const HeaderWithContent: Component<
  HeaderWithContentFields & { class?: string }
> = (inProps) => {
  const props = mergeProps(
    {
      heading: "Heading Text",
      bodyContent:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis minima eius animi similique, quia ex voluptates eaque molestias ipsum excepturi sunt hic cupiditate nihil doloremque? Reiciendis autem doloremque eaque sequi.",
    },
    inProps
  );
  return (
    <div
      class={`w-full py-8 xl:py-16 flex flex-col ${
        !props.isColumn ? "md:flex-row" : ""
      } justify-between `}
      classList={{
        [`${props.class}`]: !!props.class,
        "boundary-inner": !props.fullWidth,
      }}
    >
      <div
        class={`font-bold basis-1/3 ${
          props.isColumn ? "text-lg" : "text-3xl leading-10"
        } mb-6`}
      >
        {props.heading}
      </div>
      <div
        class={`basis-2/3 leading-6  ${
          !props.isColumn ? "md:ml-12 text-roma-dark-grey" : ""
        }`}
      >
        <div
          innerHTML={props.bodyContent}
          class={props.isColumn ? "leading-8" : ""}
        />
        <Show when={props.urlText && props.urlAddress}>
          <A
            href={props.urlAddress}
            class="text-roma-blue text-sm font-bold pt-6 underline-hover underline-roma-blue inline-block"
            target={props.openNewTab ? "_blank" : undefined}
          >
            {props.urlText}
          </A>
        </Show>
      </div>
    </div>
  );
};

export default HeaderWithContent;
